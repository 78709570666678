import service from '@/utils/request';
export var getTableauView = function getTableauView(params) {
  return service({
    url: "/tableau/getTableauView",
    method: 'get',
    params: params
  });
};
export var getTableuTicketUrl = function getTableuTicketUrl(params) {
  return service({
    url: "/tableau/getTableuTicketUrl",
    method: 'get',
    params: params
  });
};

//getSSRSUrl
export var getSSRSUrl = function getSSRSUrl(params) {
  return service({
    url: "/tableau/getSSRSUrl",
    method: 'get',
    params: params
  });
};
export var getNtlmRequest = function getNtlmRequest(params) {
  return service({
    url: "/digest/getNtlmRequest",
    method: 'get',
    params: params
  });
};
export var getBasicRequest = function getBasicRequest(params) {
  return service({
    url: "/digest/getBasicRequest",
    method: 'get',
    params: params
  });
};